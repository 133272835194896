<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: "ReassignmentUnsuccessfulModal",
    components: {
      ...vm.$layouts.getLayouts([
          'ArDialog',
          'ArButton',
          'ArListCard'
      ])
    },
    props: {
      id: {
        type: String,
        default: 'reassignment-unsuccessful--modal'
      },
      reassignment__data: {
        type: [Array, Object]
      },
    },
    data: () => ({
      current: {}
    }),
    computed: {
      parent() {
          let p = this.$parent
          let count = 0

          while (p.$vnode.componentOptions.tag != 'ar-master' && count < 6) {
              p = p['$parent']
              count++
          }

          return p
      },
      env() {
        return process.env.VUE_APP_URL
      },
      errorData() {
        const data = this.reassignment__data
        return [...(data?.hasActive ?? []), ...(data?.notAssignedToCampaign ?? []), ...(data?.duplicatesFound ?? []), ...(data?.others ?? [])]
      }
    },
    methods: {
      setCurrent(index, item) {
        this.current = this.current.index == index ? {} : { index, data: item }
      }
    }
  }
</script>

<template>
  <ArDialog
    :id="id"
    mbClass="d-flex justify-content-between p-0"
    :has_footer="false" 
    :has_button="false" 
    custom_width="926px" 
    custom_maxwidth="100%" 
    dialog_style="padding: 0"
    large
  >
    <section class="text-left duplicate-section">
      <img src="/static/svg/lead-tracker/exhausted_combo.svg" alt="some-icon" class="exhausted-icon">
      <h5 class="title d-inline-block ml-3">Conflicts were encountered during reassignment</h5>
      <span class="sub-title d-block mb-3">The following listings have encountered problems during reassignment. Each listing has their reason associated with them.</span>
      <div class="scrollable-view mb-3">
        <ArListCard
          v-for="(item, index) in errorData"
          :key="`reassignment-item--${index}`"
          class="mb-2"
          :lid="item.model.id"
          :status="parent.getQFlag(item.qflag_id)"
          :status-img="parent.getQFlagIcon(item.qflag_id)"
          :seller="item.model.seller?.name"
          :campaign="item.model.campaign.name"
          :campaign-img="parent.getImage('campaigns', item.model.campaign.name)"
          :researcher="item.model.user.first_name + ' ' + item.model.user.last_name"
          :researcher-img="parent.getImage('users', 'Researcher')"
          :listing_title="item.model.title[0]?.value"
          :error_message="item.errorMessage"
          @click.native="setCurrent(index, item)"
          :toggled="current?.index == index"
          is-hoverable
        />
      </div>
    </section>
    <section class="splash-image">
      <div class="position-relative pl-3 pt-2 mt-5" style="z-index: 2" v-if="current?.data?.errorBag?.duplicates?.length">
        <span class="sub-title d-block mb-3 pr-3">The following are data that conflicts with the current selected listing.</span>
        <div class="scrollable-view mb-3 pr-3">
          <ArListCard
            v-for="(item, index) in current?.data?.errorBag?.duplicates"
            :key="`duplicate-item--${index}`"
            class="mb-2"
            :lid="item.id"
            :status="item.research_status"
            :status-img="parent.getQFlagIcon(item.qflag_id)"
            :seller="item.name"
            :campaign="item.campaign_name"
            :campaign-img="parent.getImage('campaigns', item.campaign_name)"
            :researcher="item.first_name + ' ' + item.last_name"
            :researcher-img="parent.getImage('users', 'Researcher')"
            :listing_title="item.listing_title"
            :listing_url="item.url"
          />
        </div>
      </div>
      <img src="/static/svg/lead-tracker/bg-exhausted.png" class="bg" alt="bg-splash-img">
    </section>
  </ArDialog>
</template>

<style scoped>
  .duplicate-section {
    padding: 27px 30px 0 30px;
  }

  section {
    width: calc(100% - 370px);
  }

  .title {
    font-size: 18px;
    color: #383A3D;
    font-weight: bold;
  }

  .sub-title {
    font-size: 13px;
    font-weight: normal;
  }

  .scrollable-view {
    overflow: auto;
    height: 425px;
  }

  .downloadable-section {
    font-size: 13px;
  }

  .downloadable-section a {
    text-decoration: underline;
  }

  .splash-image {
    width: 370px;
    min-height: 565px;
    position: relative;
    text-align: left;
    -webkit-transition: .5s ease;
    transition: .5s ease;
  }

  .splash-image .bg {
    position: absolute;
    right: -9px;
    top: -6px;
    z-index: 0;
    pointer-events: none;
  }

  .bulk-update-button {
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 86%;
  }

  .bulk-update-button:disabled {
    opacity: 0.5;
  }

  .verify-exhausted-button {
    background: #DC3C40;
    color: #fff;
    width: 286px;
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
    font-weight: 600;
    letter-spacing: 1.25px;
    opacity: 1;
  }

  .verify-exhausted-button:hover {
    background: #F5686C !important;
  }
</style>